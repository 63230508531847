@font-face {
    font-family: 'Imprint MT Shadow';
    src: local('Imprint MT Shadow'), url(../font/IMPRISHA.TTF) format('truetype');
}

.logo {
    height: 18%;
    width: 18%;
}

.bannerColor {
    background-color: #0007CC;
    color: #000000;
}

.bgImg {
    position: relative;
}

.bgImg::after {
    content: "";
    background-image: url('../../images/laboratory-glassware.jpg');
    background-size: contain;
    background-position: center;
    opacity: 0.25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
}

.bgImg2 {
    position: relative;
}

.bgImg2::after {
    content: "";
    background-image: url('../../images/foam-small.png');
    background-size: contain;
    background-position: center;
    opacity: 0.35;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.bgImg3 {
    position: relative;
}

.bgImg3::after {
    content: "";
    background-image: url('../../images/cleaner-small.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.35;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.bgImg4 {
    position: relative;
}

.bgImg4::after {
    content: "";
    background-image: url('../../images/coating-small.png');
    background-size: contain ;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.35;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.bgImg5 {
    position: relative;
}

.bgImg5::after {
    content: "";
    background-image: url('../../images/drums-small.png');
    background-size: contain ;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.35;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.bgImg6 {
    position: relative;
}

.bgImg6::after {
    content: "";
    background-image: url('../../images/tanks-small.png');
    background-size: contain ;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.35;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.bgImg7 {
    position: relative;
}

.bgImg7::after {
    content: "";
    background-image: url('../../images/engineering-small.png');
    background-size: contain ;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.35;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}


.footerMain {
    background-color: #0b5ed7;
    color: #ffffff;
}

.btn-cal {
    color: #fff;
    background-color: #0007CC;
    border-color: #0007CC;
  }

  .btn-cal:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
  }

  .card-img-top {
    width: 100%;
    height: 20vw;
    object-fit: cover;
}